.social-bar {
    padding-top: 0.5vh;
    display: flex;
    /* flex-direction: row-reverse;  */
    padding-left: 2vw;
}

.social-icon {
    max-width: 30px;
    max-height: 3vh;
    transition-duration: 0.5s;
}

.social-icon:hover {
    transform: rotate(0.04turn);
    transition-duration: 0.5s;
}