@keyframes jiggle {
    10% {
        transform: rotate(0.02turn);
    }

    15% {
        transform: rotate(-0.02turn);
    }

    17% {
        transform: rotate(0turn);
    }

}

.bookmark {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 9svh;
    left: 0svw;
    transition: 1s;
    animation: jiggle 5s infinite;
    animation-timing-function: ease-in-out;
    /* transition-timing-function: linear; */
}

.bookmark.expanded {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 9svh;
    left: 19.5svw;
    transition: 1s;
    /* transition-timing-function: linear; */
}

/* .vertical-tapered-rectangle {
    width: 200px; 
    height: 200px; 
    background-color: #4CAF50;
    clip-path: ellipse(50% 100% at 0% 0%);
  } */

.side-bar {
    background-color: whitesmoke;
    height: 100svh;
    width: 20svw;
    position: absolute;
    z-index: 10;
    left: -19.5svw;
    transition: 1s;
    /* transition-timing-function: linear; */
}

.side-bar.expanded {
    background-color: whitesmoke;
    height: 100svh;
    width: 20svw;
    position: absolute;
    z-index: 10;
    transition: 1s;
    left: 0;
    /* transition-timing-function: linear; */

    color: rgb(50, 80, 120);
}


@media screen and (orientation:portrait) {
    .small-icon {
        max-height: 2.5svh;
    }

    :root {
        --sidebar-item-font-size: 1svh;
    }

}

@media screen and (orientation:landscape) {
    .small-icon {
        max-height: 10svh;
    }

    :root {
        --sidebar-item-font-size: 1.5svh;
    }

}

.header-whitespace {
    height: 10vh;
}

.side-bar-item {
    display: flex;
    flex: 1;
    height: 100%;
    max-height: 5svh;
    overflow: hidden;
    align-items: center;
    /* the 4vw is placeholder for a pixelart */
    /* padding: 1svh 6svw 1svh 2svw; */

    font-size: var(--sidebar-item-font-size);

    /* box-shadow: inset 0 0 10px rgb(200, 200, 200); */
    /* background-color: aqua; */
    border-radius: 10px;
}


.side-bar-item.text {
    display: flex;
    padding: 1svh 1svw 1svh 2svw;
    font-size: var(--sidebar-item-font-size);

    text-decoration: none;
    color: inherit;
}

.side-bar-item img {
    display: flex;
    max-width: 4vw;
    padding: 0 2svw 0 0;
}


.border-skin-line {
    background-image: linear-gradient(90deg, rgb(232, 178, 0), transparent);
    margin: 0 8svw 0 0svw;
    height: 2px;
    border: none;
}

.side-bar-item:hover {
    background-color: rgb(232, 237, 245);
    transition: 0.2s ease-in;
    cursor: pointer;
}

.side-bar-link {
    text-decoration: none;
}