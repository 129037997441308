
@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    /* width: 250px; */
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  .logos:before {
    left: 0;
  }
  
  .logos:after {
    right: 0;
  }

  
  .logos-slide {
    display: inline-block;
    animation: 20s slide infinite linear;
  }
  
  .logos-slide img {
    height: 50svh;
    margin: 0 2px;
    opacity: 40%;
  }

  .logos-slide img:hover {
    opacity: 100%;
    transition: 0.3s;
  }
  