
@media screen and (orientation:portrait) {
    :root {
        --header-font-size: 3svw;
    }    
}

@media screen and (orientation:landscape) {
    :root {
        --header-font-size: 1.7svw;
    }    
}


.header {
    text-decoration: none;
    font-weight: bold;
    font-size: var(--header-font-size);
    left: 0;
    position: absolute;
    padding: 4svh 0 0 2svw;
    color: whitesmoke;
    z-index: 10;

}

.header.lower{
    padding: 6svh 0 0 2svw;
}

.header-black {
    text-decoration: none;
    font-weight: bold;
    font-size: var(--header-font-size);
    left: 0;
    position: absolute;
    padding: 4svh 0 0 2svw;
    color: rgb(50, 80, 120);
    z-index: 11;

    white-space: nowrap;
    max-width: 0svw;
    overflow: hidden;
    transition: all 0.55s linear;
}

.header-black.lower{
    padding: 6svh 0 0 2svw;
}


.header-black.visible{
    max-width: 20svw;
    overflow: hidden;
    transition: all 1s ease-in;
}